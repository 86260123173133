import React from 'react';
import * as Icon from 'react-feather';

const ContactInfo = () => {
    return (
        <div className="contact-info-area ptb-80">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box height-100">
                            <div className="icon">
                                <Icon.Mail />
                            </div>
                            <h3>E-Mail Adresse</h3>
                            <p><a href="mailto:admin@spet.com">info@kinetik.de</a></p>
                         
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box height-100">
                            <div className="icon">
                                <Icon.MapPin />
                            </div>
                            <h3>Besucht uns Hier</h3>
                            <p>KiNETiK Gesellschaft für Informationstechnik mbH
Raiffeisenstr. 3
95463 Bindlach
Deutschland</p>
                        </div>
                    </div>

                    <div className="col-lg-4 col-md-6 col-sm-6">
                        <div className="contact-info-box height-100">
                            <div className="icon">
                                <Icon.Phone />
                            </div>
                            <h3>Rufen sie uns an unter</h3>
                            <p><a href="tel:+1234567890">+49 (0) 921 – 730050 – 0</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContactInfo;  