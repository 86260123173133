import React from "react";
import Layout from "../components/_App/layout";
import SEO from "../components/_App/seo"; 
import Navbar from "../components/Kinetik/Navbar";
import Footer from "../components/Kinetik/Footer";
import PageBanner from '../components/Common/PageBanner'; 
import ContactInfo from '../components/Kinetik/ContactInfo2';
import ContactImg from "../assets/images/contact-img.png";
import ContactForm2 from '../components/Kinetik/ContactForm2';

const Contact = () => (
    <Layout>
        <SEO title="Kontaktieren Sie uns" />

        <Navbar />

        <PageBanner pageTitle="Kontaktieren Sie uns" />

        <ContactInfo />

		<div class="container">
			<div class="row justify-content-center">
				{/*<div class="col-lg-6 col-md-12">
					<ContactForm2 />
				</div>*/}
				<div class="col-lg-6 col-md-12">
				<img src={ContactImg} />
				</div>
			</div>
		</div>
        
        <Footer /> 
    </Layout>
)

export default Contact;